<template>
  <v-container id="dashboard" fluid tag="section">

  <v-form ref="eventTime">
    <base-material-card icon="mdi-clock-time-four">
      <slot slot="title">Event Times</slot>
      <slot slot="rightButtons">
        <toggle-button text="Add a new Time" openIcon="mdi-plus" @toggle="showCreate()" :showing="showCreateToggle"/>
      </slot>
      <p>Each event can have many Event Times (occurances).  For example, the event could happen on Wednesday, Thursday and Friday night.  If your
         event only happens once,
        just add a single start and end time.
      </p>
      <p>If your event has a lot of occurances (Event Times), then you can use this handy 
        <a color="secondary" :loading="disableGenerate" @click="generateToggle = true">Event Time Generator</a></p>
      <div class="mx-3 my-10" v-if="showCreateToggle || !eventTimes.length">
        <h3>New Start and End Times</h3>
        <div :class="{'d-flex': $vuetify.breakpoint.smAndUp}">
          <div class="d-flex">
            <span class="small mr-2">Event starts</span> 
            <datetime input-id="start" class="mb-5 mr-2" input-style="border-bottom:1px solid" 
            v-model="start" type="datetime" :max-datetime="end"/>
          </div> 
          <div class="d-flex">
            <span class="small mr-2"> and ends </span>
            <datetime input-id="end" class="mb-5" input-style="border-bottom:1px solid" v-model="end" type="datetime" :min-datetime="start"></datetime>
            <v-icon class="ml-2 mt-n5" large color="success" :disabled="!(start && end)" @click="createEventTime">mdi-checkbox-marked-circle</v-icon>
            <v-icon class="ml-2 mt-n5" large @click="showCreateToggle = false">mdi-close-circle</v-icon>
          </div> 
        </div>

      </div>
      <div v-if="eventTimes.length">
        <div v-if="pastEventTimes.length"> 
          <v-tabs>
            <v-tab>Upcoming Event Times</v-tab>
            <v-tab>Past Event Times</v-tab>
            <v-tab-item>
              <eventTimeList :eventTimes="futureEventTimes" @deleteEventTime="deleteEventTime"/>
            </v-tab-item>
            <v-tab-item>
              <eventTimeList :eventTimes="pastEventTimes" @deleteEventTime="deleteEventTime"/>
            </v-tab-item>
          </v-tabs>
        </div>
        <div v-else>
          <eventTimeList :eventTimes="eventTimes" @deleteEventTime="deleteEventTime"/>
        </div>
      </div>
    </base-material-card>
  </v-form>

  <v-dialog v-show="generateToggle" v-model="generateToggle" persistent max-width="600px">
    <v-card>
      <v-card-title>Generate Event Times              
        <v-spacer></v-spacer>
        <v-icon large @click="generateToggle = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <p>Choose the days and times for your event, and click preview to see the Event Times will be created</p>
          <v-row>
            <v-col cols="6">
              Start Date
            </v-col>
            <v-col cols="6">
              <datetime input-id="generateStartDate" v-model="generateStartDate" input-style="border-bottom:1px solid" type="date"/>
              <span class="small body-2" style="color:red">{{generateStartDateError}}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              End Date
            </v-col>
            <v-col cols="6">
              <datetime input-id="generateEndDate" v-model="generateEndDate" input-style="border-bottom:1px solid" type="date"/>
              <span class="small body-2" style="color:red">{{generateEndDateError}}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Start Time (every day)
            </v-col>
            <v-col cols="6">
              <datetime input-id="generateStartTime" v-model="generateStartTime" input-style="border-bottom:1px solid" type="time"/>
              <span class="small body-2" style="color:red">{{generateStartTimeError}}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              End Time (every day)
            </v-col>
            <v-col cols="6">
              <datetime input-id="generateEndTime" v-model="generateEndTime" input-style="border-bottom:1px solid" type="time"/>
              <span class="small body-2" style="color:red">{{generateEndTimeError}}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12">
              Days of the week
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="12">
              <v-select v-model="days" :items="allDays" attach chips multiple/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Multiple Times per day?
            </v-col>
            <v-col cols="6">
              <v-switch class="mt-0" v-model="multiple"/>
            </v-col>
          </v-row>
          <v-row v-if="multiple">
            <v-col cols="6">
              How long is each Event Time (minutes)
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="minutes" required :rules="[utils.integerRule]"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="generateToggle = false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="preview">Preview</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-show="previewToggle" v-model="previewToggle" persistent max-width="600px">
    <v-card>
      <v-card-title>Generate Event Times              
        <v-spacer></v-spacer>
        <v-icon large @click="previewToggle = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <p>Here are the Event Times that will be generated.</p>
          <v-row>
            <v-col cols="6">Start Time</v-col>
            <v-col cols="6">End Time</v-col>
          </v-row>
          <v-row v-for="(eventTime, i) in generated" :key="i">
            <v-col cols="6">{{utils.dateToNiceString(eventTime.start)}}</v-col>
            <v-col cols="6">{{utils.dateToNiceString(eventTime.end)}}</v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="previewToggle = false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="generate()">Generate</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import axios from "axios";
  import Store from '@/services/Store.js';
  import DateTime from 'luxon/src/datetime.js'

  export default {
    name: 'Event',

    components: {
      Tooltip: () => import('./components/Tooltip'),
      EventTimeList: () => import('./EventTimeList'),
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
    },  

    data () {
      return {
        allDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
        generateToggle: false,
        previewToggle: false,
        generateStartTime: null,
        generateEndTime: null,
        generateStartDate: null,
        generateEndDate: null,
        generateStartTimeError: null,
        generateEndTimeError: null,
        generateStartDateError: null,
        generateEndDateError: null,
        minutes: 30,
        multiple: false,
        editEventTimeId: null,
        start: null,
        end: null,
        event: {},
        boxOffice: {},
        eventTimes: [],
        generated: [],
        user: {},
        showCreateToggle: false,
        disableGenerate: false,
      }
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
    },

    created() {
      this.event.id = this.$route.params.id;
      this.load();
    },

    methods: {

      percent(event) {
        if (!event.soldTickets) {
          return 0;
        }
        return  (100 * event.soldTickets / (event.soldTickets + event.remainingTickets)).toFixed(0);
      }, 

      showCreate() {
        this.showCreateToggle = !this.showCreateToggle;
        this.start = null;
        this.end = null;
      },

      preview() {

        this.generated = [];
        var minute = 1000 * 60;
        var hour = minute * 60;
        var day = hour * 24;
        this.generateStartDateError = null;
        this.generateStartTimeError = null;
        this.generateEndDateError = null;
        this.generateEndTimeError = null;
        if (!this.generateStartDate) {
          let error = "please enter a start date";
          this.generateStartDateError = error;
          console.log(error);
          return;
        }
        if (!this.generateEndDate) {
          let error = "please enter an end date";
          this.generateEndDateError = error;
          console.log(error);
          return;
        }
        if (!this.generateStartTime) {
          let error = "please enter a start time";
          this.generateStartTimeError = error;
          console.log(error);
          return;
        }
        if (!this.generateEndTime) {
          let error = "please enter an end time";
          this.generateEndTimeError = error;
          console.log(error);
          return;
        }


        let lStartDate = DateTime.fromISO(this.generateStartDate);
        let lEndDate = DateTime.fromISO(this.generateEndDate);
        let lStartHour = DateTime.fromISO(this.generateStartTime).hour;
        let lStartMinute = DateTime.fromISO(this.generateStartTime).minute;
        let lEndHour = DateTime.fromISO(this.generateEndTime).hour;
        let lEndMinute = DateTime.fromISO(this.generateEndTime).minute;

        lStartDate = lStartDate.startOf('day');
        lEndDate = lEndDate.startOf('day');

        while (lStartDate <= lEndDate) {
          let lStartDateTime = lStartDate.set({ hour: lStartHour, minute: lStartMinute })
          if (this.days.includes(Utils.dateToDay(lStartDateTime))) {
            if (this.multiple) {
              let lEndDateTime = lStartDateTime.set({ hour: lEndHour, minute: lEndMinute })
              while (lStartDateTime < lEndDateTime) {
                let eventTime = {};
                eventTime.start = lStartDateTime.toMillis();
                lStartDateTime = lStartDateTime.plus({ minutes: this.minutes });
                eventTime.end = lStartDateTime.toMillis();
                this.generated.push(eventTime);
              }
            } else {
              let eventTime = {};
              eventTime.start = lStartDateTime.toMillis();
              eventTime.end = lStartDateTime.set({ hour: lEndHour, minute: lEndMinute }).toMillis()
              this.generated.push(eventTime);
            }
          }
          lStartDate = lStartDate.plus({ days: 1 });
        }

        if (this.generated.length > 100) {
          alert("sorry, we can only generate a maximum of 100 event times in one go, please adjust your dates");
          return;
        }
        this.previewToggle = true;
      },

      tomorrow(date) {
        return this.$moment(date).add(1,'days').toDate().getTime()
      },

      async generate() {
        this.previewToggle = false;
        this.generateToggle = false;
        this.disableGenerate = true;
        for (var eventTime of this.generated) {
          eventTime.eventId = this.event.id;
          await Api.post(this, "EventTime", "create", eventTime);
        }
        await this.readEventTimes();
        this.disableGenerate = false;
      },

      async load() {
        this.event = await Api.post(this, "Event", "read", this.event);
        this.readBoxOffice();
        this.readEventTimes();
      },

      async readEventTimes() {
        this.eventTimes = await Api.post(this, "EventTime", "listWithSales", this.event);
        this.pastEventTimes = [];
        this.futureEventTimes = [];
        let now = new Date().getTime();
        if (this.eventTimes.length > 10) {
          for (let eventTime of this.eventTimes) {
            if (eventTime.end < now) {
              this.pastEventTimes.push(eventTime)
            } else {
              this.futureEventTimes.push(eventTime)
            }
          }
        }
      },

      async readBoxOffice() {
        this.boxOffice.id = this.event.boxOfficeId;
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice, this.event);
      },

      async createEventTime() {
        var eventTime = {}
        eventTime.start = Utils.dateToDateTime(this.start);
        eventTime.end = Utils.dateToDateTime(this.end);
        eventTime.eventId = this.event.id;
        await Api.post(this, "EventTime", "create", eventTime);
        this.showCreateToggle = false;
        this.readEventTimes();
      },

      async deleteEventTime(eventTime) {
        await Api.post(this, "EventTime", "delete", eventTime);
        this.readEventTimes();
      },

      selectItem(eventTime) {
        this.$router.push("/Dashboard/EventTime/" + eventTime.id)
      },
    }
  }
</script>

<style lang="sass" scoped>
  .v-application a
    color: black
    text-decoration: underline
    font-weight: bold
</style>